import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { SearchContextProvider } from '/machinery/SearchContext'

import { Selector } from '/features/pageOnly/Selector'

const client = new QueryClient()

export default function SelectorUniversal({ image, issue, banks, issues, specials }) {
  return (
    <SearchContextProvider {...{ issue }}>
      <QueryClientProvider {...{ client }}>
        <Selector {...{ image, issue, banks, issues, specials }} />
      </QueryClientProvider>
    </SearchContextProvider>
  )
}
